import React from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import Button from '../components/Button/Button';
import { View } from '../components/View/View';
import { Visible } from '../components/Visible/Visible';
import YouTube from 'react-youtube';
import ListItems from '../components/ListItems/ListItems';
import Form from '../components/Form/Form';
import FormSupplier from '../components/FormSupplier/FormSupplier';
import { BigView } from '../components/BigView/BigView';

function Supplier() {
  const _onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const { supplier } = useStaticQuery(
    graphql`
      query {
        supplier: allDatoCmsSupplierNotIndexed {
          nodes {
            quotationTemplate {
              url
            }
            title
            titleService
            titleWorkflow
            youtubeVideoId
            workflowContent {
              text
              title
            }
            serviceContent {
              text
              title
            }
          }
        }
      }
    `
  );

  const supplierContent = supplier.nodes[0];
  return (
    <>
      <LayoutWrapper>
        <BigView>
          <View
            top={3.5}
            desktopLeft={6}
            desktopRight={6}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            bottom={2}
          >
            <Title
              color={design.colors.blue150}
              content={supplierContent.title}
            />
            <View top={2} />
            <Visible mobile tablet>
              <YouTube
                videoId={supplierContent.youtubeVideoId}
                opts={{ height: '193', width: '100%' }}
                onReady={_onReady}
              />
            </Visible>
            <Visible desktop>
              <YouTube
                videoId={supplierContent.youtubeVideoId}
                opts={{ height: '720', width: '100%' }}
                onReady={_onReady}
              />
            </Visible>

            <View top={3} center>
              <Title
                color={design.colors.blue150}
                content={supplierContent.titleWorkflow}
              />
            </View>
            <View mobileLeft={0.5} mobileRight={0.5}>
              <ListItems
                enableNumberItems
                items={supplierContent.workflowContent}
              />
            </View>
            <View top={3} center>
              <Title
                color={design.colors.blue150}
                content={supplierContent.titleService}
              />
            </View>
            <View mobileLeft={0.5} mobileRight={1}>
              <ListItems items={supplierContent.serviceContent} />
            </View>

            <View top={5} />
            {/* <input id="input-file" type="file" value="" /> */}
            <FormSupplier fileUrl={supplierContent.quotationTemplate.url} />
          </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default Supplier;
