import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import Button from '../Button/Button';
import { useForm } from 'react-hook-form';
import { View } from '../View/View';
import { Visible } from '../Visible/Visible';

import FileBase64 from 'react-file-base64';
import { navigate } from '@reach/router';

import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const StyledForm = styled.div`
  .flex-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* align-content: space-between; */
  }
`;

const GridForm = styled.div`
  display: grid;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    grid-template-columns: auto;
    grid-gap: 50px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    grid-template-columns: auto auto;
    justify-content: center;
    grid-gap: 50px;
  }
`;

const Input = styled.input`
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #cecece;
  font-size: 1rem;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    width: 100%;
    height: 50px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    width: 300px;
    height: 50px;
  }
`;

const UploadFile = styled.input`
  /* padding: 1rem;
  border-radius: 5px;
  border: 1px solid #cecece;
  font-size: 1rem;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    width: 100px;
    height: 50px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    width: 100px;
    height: 50px;
  } */
`;

const Label = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: ${design.colors.dark};
  text-transform: uppercase;
  margin-bottom: 10px;

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    font-size: 10px;
    line-height: 14px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const TitleForm = styled.h3`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: ${design.colors.primaryBlue};
`;

const FormContainer = styled.div`
  background-color: #ebebeb;
  border-radius: 5px;
  padding: 1rem;
`;

const ContentForm = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 0.83em;
  font-weight: normal;
`;

// const file64 = 'VGVzdGUgdGVzdGU=';

// const ErrorMessage = styled.span`
//   color: ${design.colors.primaryOrange};
//   font-size: 0.7rem;
//   text-transform: uppercase;
// `;
function FormSupplier(props) {
  const { handleSubmit } = useForm();
  const [docBase64, setDocBase64] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(false);

  const onSubmit = () => {
    if (docBase64 === '') {
      store.addNotification({
        title: 'Error!',
        message: 'Please add your template.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
    } else {
      setIsDisabled(true);
      window.Email.send({
        Host: 'smtp.sendgrid.net',
        Username: 'apikey',
        Password:
          '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',
        To: 'linkbox17@gmail.com',
        From: 'nao-responda@linkbox.com.br',
        Subject: 'Um novo documento do fornecedor foi recebido!',
        Body: `<h3>Novos dados do fornecedor disponíveis para download:</h3><br/><br/>`,
        Attachments: [
          {
            name: `template.xlsx`,
            data: docBase64,
          },
        ],
      }).then(() => {
        setIsDisabled(false);
        store.addNotification({
          title: 'Success!',
          message:
            'Thank you for submitting your data. We will contact you soon.',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
          onRemoval: () => {
            navigate('/produtos');
          },
        });
      });
    }
  };
  return (
    <>
      <StyledForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <View top={2} />
          {/* <GridForm> */}
          <Visible tablet desktop>
            <ContentForm>
              <div>
                <TitleForm>Step 1:</TitleForm>
                <TitleContainer>Download our quotation template</TitleContainer>
                <View top={1} />
                <Button
                  href={props.fileUrl}
                  type="button"
                  content="Download"
                  width="block"
                  bgColor="primaryOrange"
                />
              </div>
              <div>
                <TitleForm>Step 2:</TitleForm>
                <TitleContainer>Upload template</TitleContainer>
                <View top={1} />
                <FileBase64
                  multiple={false}
                  onDone={e => {
                    setDocBase64(e.base64);
                  }}
                />
              </div>
            </ContentForm>
          </Visible>
          {/* </GridForm> */}
          <Visible mobile>
            <GridForm>
              <div>
                <TitleForm>Step 1:</TitleForm>
                <TitleContainer>Download our quotation template</TitleContainer>
                <View top={1} />
                <Button
                  href={props.fileUrl}
                  type="button"
                  content="Download"
                  width="block"
                  bgColor="primaryOrange"
                />
              </div>
              <div>
                <TitleForm>Step 2:</TitleForm>
                <TitleContainer>Upload template</TitleContainer>
                <View top={1} />
                <FileBase64
                  multiple={false}
                  onDone={e => {
                    setDocBase64(e.base64);
                  }}
                />
              </div>
            </GridForm>
          </Visible>
          <View top={6} />
          <Visible mobile>
            <Button content="Submit" width="block" bgColor="primaryGreen" />
          </Visible>
          <Visible desktop tablet>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                content="Submit"
                disabled={isDisabled}
                width="block"
                bgColor="primaryGreen"
              />
            </div>
          </Visible>
        </form>
      </StyledForm>
    </>
  );
}

export default FormSupplier;
