import React from 'react';
import styled from 'styled-components';
import Text from '../Text/Text';
import design from '../../config/design';
import { View } from '../View/View';
import { Flex } from '../Container/Container';

const TitleListItem = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: ${props => props.color || design.colors.primaryBlue};
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    font-size: 16px;
    line-height: auto;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    font-size: 16px;
    line-height: auto;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    font-size: 20px;
    line-height: auto;
  }
`;

const CircleNumber = styled.div`
  /* background: lightblue; */
  border: 1px solid #16bac5;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #16bac5;
  position: relative;
  left: -14px;
  top: 25px;
`;

function ListItems(props) {
  return (
    <>
      {props.items.map((item, i) => (
        <>
          <View top={2} />
          {props.enableNumberItems ? (
            <CircleNumber>{i + 1}</CircleNumber>
          ) : null}
          <View left={2}>
            <TitleListItem>{item.title}</TitleListItem>
            <Text content={item.text} />
          </View>
          {props.items.length > i + 1 && props.enableNumberItems ? (
            <View
              top={10}
              style={{ borderLeft: `1px solid #16BAC5`, marginTop: -10 }}
            />
          ) : (
            <View top={3} />
          )}
        </>
      ))}
    </>
  );
}

export default ListItems;
