import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const StyledText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => props.color || design.colors.dark};

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    font-size: 12px;
    line-height: 18px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Text = props => {
  return (
    <StyledText color={props.color} bold={props.bold}>
      <ReactMarkdown source={props.content} escapeHtml={false} />
    </StyledText>
  );
};

export default Text;
